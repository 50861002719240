import defineComponent from '../../../components/flight/lib/component';

export default defineComponent(PaidButNotReadyMessage);

function PaidButNotReadyMessage() {

    this.setOrder = function (event, data) {
        this.order = data;
        const thisItem = this.order.items.find(item => item.id === this.itemId);

        switch (this.messageType) {
            case "order": {
                if(!this.order.artworkReady && this.order.payments.length > 0) {
                    this.$node.removeClass("hidden");
                } else {
                    this.$node.addClass("hidden");
                }
                break;
            }
            case "item": {
                if(!thisItem.map.ready && this.order.payments.length > 0) {
                    this.$node.removeClass("hidden");
                } else {
                    this.$node.addClass("hidden");
                }
                break;
            }
        }
    };

    this.after('initialize', function () {
        this.messageType = $(this.node).data('message-type');
        this.itemId = $(this.node).data('item-id');
        this.on(document, "setOrderData", this.setOrder);
    });

}
