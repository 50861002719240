import mixam from '../../boot/mixam';
import Notify from '../../../components/notify.js/notify';

export default function WithOrdersItemsMembers() {

    this.category = {
        STATUS: "Order status change",
        PAYMENT: "Payment",
        ARTWORK: "Artwork readiness",
        REMARKS: "Remarks added"
    };

    this.orientationIcons = [
        "mx-neil-portrait",
        "mx-neil-landscape"
    ];

    this.shops = {
        "56b3c7235e1cc70ad83d3e0e": {
            title: "Mixam",
            icon: "mx-mixam-logo-smart"
        },
        "5f98203cd46d75224b02f41c": {
            title: "PrintMX",
            icon: "mx-printmx-logo"
        },
    };

    this.askForNotifyPermissions = function () {
        $(document).off("click.ask.for.notify.permissions");
        if (Notify.needsPermission && Notify.isSupported()) {
            Notify.requestPermission();
        }
    };

    this.isRelevantMessage = function (data) {
        const start = this.filter === "none" ? -1 : 0;
        if (data.type && data.category && data.status && data.order.status === this.attr.status) {
            if (data.order.shopId === mixam.shop.id && data.order.orderStatusInt > start) {
                return true;
            }
        }
        return false;
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function (callback) {
        this.trigger("log", {message: ["Subscribe to stomp channel shopallmessages"], title: "WithOrdersItemsMembers.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(), 10);
            }
            this.trigger("log", {message: args, title: "WithOrdersItemsMembers.subscribe"});
        };


        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/shopallmessages", d => {
                const p = JSON.parse(d.body),
                    thumb = this.getThumbUrl(p.order);

                if (callback) {
                    callback(p);
                }

                let description = p.reason;
                if (!description) {
                    switch (p.category) {
                        case "STATUS":
                            description = "Order status is " + p.order.orderStatus;
                            break;
                        case "PAYMENT":
                            description = "Order is" + (p.order.settled ? "" : " not") + " settled";
                            break;
                        case "ARTWORK":
                            description = "Artwork is" + (p.order.artworkReady ? "" : " not") + " ready";
                            break;
                        case "REMARKS":
                            description = "not available";
                            break;
                    }
                }

                if (!Notify.needsPermission && (p.shopId === mixam.shop.id || mixam.shop.isMixamShop)) {
                    const notification = new Notify(this.category[p.category] || 'Others', {
                        body: "{name}, Order {orderId}. {reason}".supplant({
                            orderId: p.order.caseNumber,
                            name: p.order.contact.name,
                            email: p.order.contact.email,
                            reason: description
                        }),
                        notifyClick: () => window.open("/orders/" + p.order.id),
                        tag: "x" + p.order.caseNumber,
                        timeout: 30,
                        icon: thumb ? thumb.thumb : "https://s3-eu-west-1.amazonaws.com/static3.mixam.com/shop/css/coloratura/images/mixam-logo-180.png"
                    });
                    notification.show();
                }
            });

        }, (err) =>  this.trigger("log", err), '/');

    };

    this.send = function (data) {
        this.stomp.send(this.topic, {}, data);
    };

    this.clock = function () {
        if (this.clockTimer) {
            clearTimeout(this.clockTimer);
        }
        this.clockTimer =  setTimeout(() => {
            this.clockTimer = null;
            if (!document.hidden) {
                this.paint();
            }
            this.clock();
        }, 1000 * 60);
    };

    this.getThumbUrl = function (item) {
        var map = item.map,
            page = map && map.pages[0],
            side = page && page.side ? "-" + page.side : "";

        if (page && page.file) {
            return {
                thumb: item.thumbnailUrl,
                title: item.name,
                orientation: map.orientation
            };
        }
        return {};
    };

    this.isMapNotEmpty = function(map) {
        if (map) {
            return map.pages.some(p => p.file) || map.spine?.file || map.dustJacketPages.some(p => p.file); // jshint ignore:line
        }
        return false;
    };

}
