var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Spinner, Alert, Button, Form, Row, Col, Card } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
var CustomsExportMetadataEditor = function (_a) {
    var productId = _a.productId, subProductId = _a.subProductId, santaType = _a.santaType;
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(null), successMessage = _d[0], setSuccessMessage = _d[1];
    var _e = useState(null), metadataDocumentWrapper = _e[0], setMetadataDocumentWrapper = _e[1];
    var _f = useState(false), useCustom = _f[0], setUseCustom = _f[1];
    var _g = useState(false), showConfirmationModal = _g[0], setShowConfirmationModal = _g[1];
    var _h = useState(null), formValues = _h[0], setFormValues = _h[1];
    useEffect(function () {
        setLoading(true);
        axios
            .get("/api/admin/metadata/product/customs-export/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType))
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            if (subProductId !== 0) {
                setUseCustom(response.data.productMetadataDocument !== null);
            }
            setLoading(false);
        })
            .catch(function () {
            setLoading(false);
            setError('Failed to load customs export metadata document.');
        });
    }, [productId, subProductId, santaType]);
    if (isLoading) {
        return React.createElement(Spinner, { animation: "grow", variant: "primary" });
    }
    if (!metadataDocumentWrapper) {
        return React.createElement(Alert, { variant: "danger" }, "No Copies Metadata available");
    }
    var validationSchema = Yup.object({
        exportCode: Yup.string().notRequired()
    });
    var activeDocument = useCustom ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
    var saveCustomsExportMetadata = function (values, setSubmitting) {
        var _a;
        setLoading(true);
        setError(null);
        setSuccessMessage(null);
        var activeDocument = useCustom
            ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
            : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
        var newDocument = activeDocument
            ? __assign({}, activeDocument) : {
            shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
            productId: productId,
            subProductId: subProductId,
            santaType: santaType,
        };
        var saveRequest = {
            useDefault: !useCustom,
            customsExportMetadataDocument: __assign(__assign({}, newDocument), { exportCode: values.exportCode }),
        };
        axios
            .post("/api/admin/metadata/product/customs-export/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType), saveRequest)
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            setLoading(false);
            setSubmitting(false);
            setSuccessMessage('Customs Export Metadata saved successfully!');
        })
            .catch(function (error) {
            var _a;
            setLoading(false);
            setSubmitting(false);
            setError("Failed to save customs export document: ".concat(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error.message));
        });
    };
    var handleSave = function (values, _a) {
        var setSubmitting = _a.setSubmitting;
        if (!useCustom && subProductId !== 0) {
            setShowConfirmationModal(true);
        }
        else {
            saveCustomsExportMetadata(values, setSubmitting);
        }
    };
    var handleCopyFromDefault = function () {
        var _a;
        if (metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) {
            var defaultData = metadataDocumentWrapper.defaultProductMetadataDocument;
            var updatedCustomDocument = __assign(__assign({}, metadataDocumentWrapper.productMetadataDocument || {
                shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
                productId: productId,
                subProductId: subProductId,
                santaType: santaType,
                exportCode: ''
            }), { customsExportMetadataDocument: {
                    exportCode: defaultData.exportCode
                } });
            setMetadataDocumentWrapper(__assign(__assign({}, metadataDocumentWrapper), { productMetadataDocument: updatedCustomDocument }));
        }
    };
    return (React.createElement("div", null,
        React.createElement(Formik, { initialValues: activeDocument || { exportCode: '' }, validationSchema: validationSchema, enableReinitialize: true, onSubmit: handleSave }, function (_a) {
            var isSubmitting = _a.isSubmitting, values = _a.values, setSubmitting = _a.setSubmitting;
            return (React.createElement(React.Fragment, null,
                React.createElement(Card, { className: "p-4 shadow-sm bg-light" },
                    React.createElement(FormikForm, null,
                        React.createElement("h5", { className: "mb-4" }, "Customs Export Metadata"),
                        successMessage && (React.createElement(Alert, { variant: "success", onClose: function () { return setSuccessMessage(null); }, dismissible: true }, successMessage)),
                        error && (React.createElement(Alert, { variant: "danger", onClose: function () { return setError(null); }, dismissible: true }, error)),
                        subProductId !== 0 && (React.createElement(Form.Group, { className: "mb-4" },
                            React.createElement(Form.Check, { type: "radio", label: "Use Default Metadata", checked: !useCustom, onChange: function () { return setUseCustom(false); } }),
                            React.createElement(Form.Check, { type: "radio", label: "Customize Metadata", checked: useCustom, onChange: function () { return setUseCustom(true); } }),
                            React.createElement("hr", null),
                            useCustom && (React.createElement(Button, { className: "mt-1 btn-sm", variant: "secondary", onClick: handleCopyFromDefault }, "Copy Default Values")))),
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6 },
                                React.createElement(Form.Group, { controlId: "data.initialValue" },
                                    React.createElement(Form.Label, null,
                                        React.createElement("strong", null, "Initial Value")),
                                    React.createElement(Field, { type: "text", name: "exportCode", className: "form-control", disabled: subProductId !== 0 && !useCustom }),
                                    React.createElement(Form.Text, { className: "text-muted" }, "The export code used when creating international shipping documentation"),
                                    React.createElement(ErrorMessage, { name: "exportCode", component: "div", className: "text-danger small mt-1" })))),
                        React.createElement(Button, { type: "submit", disabled: isSubmitting, variant: "primary", className: "mt-4 px-4 py-2" }, "Save Changes"))),
                React.createElement(ConfirmationModal, { show: showConfirmationModal, onHide: function () { return setShowConfirmationModal(false); }, onConfirm: function () {
                        setShowConfirmationModal(false);
                        saveCustomsExportMetadata(values, setSubmitting);
                    } })));
        })));
};
export default CustomsExportMetadataEditor;
