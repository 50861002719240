var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Formik, Form as FormikForm } from 'formik';
import { Spinner, Alert, Button, Table, Modal, Form, Card } from 'react-bootstrap';
import axios from 'axios';
import { FoilingEnum } from '@mixam-platform/mixam-types';
import ConfirmationModal from "./ConfirmationModal";
var ItemTypes = {
    ROW: 'row',
};
var FoilingMetaDataEditor = function (_a) {
    var productId = _a.productId, subProductId = _a.subProductId, santaType = _a.santaType, frontAvailable = _a.frontAvailable, backAvailable = _a.backAvailable, coverAvailable = _a.coverAvailable, dustJacketAvailable = _a.dustJacketAvailable;
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(null), successMessage = _d[0], setSuccessMessage = _d[1];
    var _e = useState(null), metadataDocumentWrapper = _e[0], setMetadataDocumentWrapper = _e[1];
    var _f = useState(false), useCustom = _f[0], setUseCustom = _f[1];
    var _g = useState(false), showModal = _g[0], setShowModal = _g[1];
    var _h = useState(false), showConfirmationModal = _h[0], setShowConfirmationModal = _h[1];
    var _j = useState(null), editIndex = _j[0], setEditIndex = _j[1];
    var _k = useState(''), sectionKey = _k[0], setSectionKey = _k[1];
    var _l = useState({
        foilingColour: FoilingEnum.RED,
    }), newOption = _l[0], setNewOption = _l[1];
    useEffect(function () {
        setLoading(true);
        axios
            .get("/api/admin/metadata/product/foiling/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType))
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            if (subProductId !== 0) {
                setUseCustom(response.data.productMetadataDocument !== null);
            }
            setLoading(false);
        })
            .catch(function () {
            setLoading(false);
            setError('Failed to load foiling metadata document.');
        });
    }, [productId, subProductId, santaType]);
    var getFoilingColourOptions = function (section, values) {
        var existingColours = (values[section] || []).map(function (option) { return option.foilingColour; });
        return [
            { value: FoilingEnum.RED, label: 'Red', disabled: existingColours.includes(FoilingEnum.RED) },
            { value: FoilingEnum.GOLD, label: 'Gold', disabled: existingColours.includes(FoilingEnum.GOLD) },
            { value: FoilingEnum.BLUE, label: 'Blue', disabled: existingColours.includes(FoilingEnum.BLUE) },
            { value: FoilingEnum.COPPER, label: 'Copper', disabled: existingColours.includes(FoilingEnum.COPPER) },
            { value: FoilingEnum.GREEN, label: 'Green', disabled: existingColours.includes(FoilingEnum.GREEN) },
            { value: FoilingEnum.SILVER, label: 'Silver', disabled: existingColours.includes(FoilingEnum.SILVER) },
        ];
    };
    var handleSave = function (values, _a) {
        var setSubmitting = _a.setSubmitting;
        if (!useCustom && subProductId !== 0) {
            setShowConfirmationModal(true);
            setSubmitting(false);
            return;
        }
        saveFoilingMetadata(values, setSubmitting);
    };
    var saveFoilingMetadata = function (values, setSubmitting) {
        var _a;
        setLoading(true);
        setError(null);
        setSuccessMessage(null);
        var activeDocument = useCustom
            ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
            : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
        var newDocument = activeDocument
            ? __assign({}, activeDocument) : {
            shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
            productId: productId,
            subProductId: subProductId,
            santaType: santaType,
        };
        var saveRequest = {
            useDefault: !useCustom,
            foilingMetadataDocument: __assign(__assign({}, newDocument), { frontFoilingOptions: values.frontFoilingOptions, backFoilingOptions: values.backFoilingOptions, coverFoilingOptions: values.coverFoilingOptions, dustJacketFoilingOptions: values.dustJacketFoilingOptions }),
        };
        axios
            .post("/api/admin/metadata/product/foiling/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType), saveRequest)
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            setLoading(false);
            setSubmitting(false);
            setSuccessMessage('Foiling Metadata saved successfully!');
        })
            .catch(function (error) {
            var _a;
            setLoading(false);
            setSubmitting(false);
            setError("Failed to save foiling metadata: ".concat(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error.message));
        });
    };
    var handleCopyFromDefault = function () {
        var _a;
        if (metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) {
            var defaultData = metadataDocumentWrapper.defaultProductMetadataDocument;
            var updatedCustomDocument = __assign(__assign({}, metadataDocumentWrapper.productMetadataDocument || {
                shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
                productId: productId,
                subProductId: subProductId,
                santaType: santaType,
            }), { frontFoilingOptions: defaultData.frontFoilingOptions || [], backFoilingOptions: defaultData.backFoilingOptions || [], coverFoilingOptions: defaultData.coverFoilingOptions || [], dustJacketFoilingOptions: defaultData.dustJacketFoilingOptions || [] });
            setMetadataDocumentWrapper(__assign(__assign({}, metadataDocumentWrapper), { productMetadataDocument: updatedCustomDocument }));
        }
    };
    var moveRow = function (section, dragIndex, hoverIndex, setFieldValue, values) {
        var updatedOptions = __spreadArray([], values[section], true);
        var removed = updatedOptions.splice(dragIndex, 1)[0];
        updatedOptions.splice(hoverIndex, 0, removed);
        setFieldValue(section, updatedOptions);
    };
    var DraggableRow = function (_a) {
        var index = _a.index, section = _a.section, option = _a.option, setFieldValue = _a.setFieldValue, values = _a.values;
        var ref = useRef(null);
        var _b = useDrop({
            accept: ItemTypes.ROW,
            hover: function (item) {
                if (!ref.current)
                    return;
                var dragIndex = item.index;
                var hoverIndex = index;
                if (item.section !== section || dragIndex === hoverIndex)
                    return;
                moveRow(section, dragIndex, hoverIndex, setFieldValue, values);
                item.index = hoverIndex;
            },
        }), drop = _b[1];
        var _c = useDrag({
            type: ItemTypes.ROW,
            item: { section: section, index: index },
            collect: function (monitor) { return ({
                isDragging: monitor.isDragging(),
            }); },
        }), isDragging = _c[0].isDragging, drag = _c[1];
        drag(drop(ref));
        return (React.createElement("tr", { ref: ref, style: { opacity: isDragging ? 0.5 : 1 } },
            React.createElement("td", null, option.foilingColour === null ? 'NOT SPECIFIED' : option.foilingColour),
            React.createElement("td", { className: "text-end" },
                React.createElement(Button, { className: "btn-sm me-2", variant: "primary", onClick: function () {
                        setEditIndex(index);
                        setNewOption(option);
                        setSectionKey(section);
                        setShowModal(true);
                    }, disabled: !isEditable }, "Edit"),
                React.createElement(Button, { className: "btn-sm", variant: "danger", onClick: function () {
                        var updatedOptions = __spreadArray([], values[section], true);
                        updatedOptions.splice(index, 1);
                        setFieldValue(section, updatedOptions);
                    }, disabled: !isEditable }, "Delete"))));
    };
    var renderTable = function (sectionKey, sectionLabel, values, setFieldValue) { return (React.createElement("div", { key: sectionKey, className: "mb-4" },
        React.createElement("h5", null, sectionLabel),
        React.createElement(Table, { bordered: true, hover: true, variant: "light" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Colours"),
                    React.createElement("th", null, "Actions"))),
            React.createElement("tbody", null, values[sectionKey].length > 0 ? (values[sectionKey].map(function (option, index) { return (React.createElement(DraggableRow, { key: index, index: index, section: sectionKey, option: option, setFieldValue: setFieldValue, values: values })); })) : (React.createElement("tr", null,
                React.createElement("td", { colSpan: 5, className: "text-center" }, "No options available."))))),
        React.createElement("div", { className: "text-end" },
            React.createElement(Button, { variant: "secondary", onClick: function () {
                    var _a;
                    setEditIndex(null);
                    var defaultColour = ((_a = getFoilingColourOptions(sectionKey, values).find(function (option) { return !option.disabled; })) === null || _a === void 0 ? void 0 : _a.value) || FoilingEnum.RED;
                    setNewOption({
                        foilingColour: FoilingEnum.RED
                    });
                    setSectionKey(sectionKey);
                    setShowModal(true);
                }, disabled: !isEditable }, "Add Option")))); };
    if (isLoading) {
        return React.createElement(Spinner, { animation: "grow", variant: "primary" });
    }
    if (!metadataDocumentWrapper) {
        return React.createElement(Alert, { variant: "danger" }, "No Foiling Metadata available");
    }
    var activeDocument = useCustom
        ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
        : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
    var isEditable = useCustom || subProductId === 0;
    return (React.createElement(DndProvider, { backend: HTML5Backend },
        React.createElement(Formik, { initialValues: {
                frontFoilingOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.frontFoilingOptions) || [],
                backFoilingOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.backFoilingOptions) || [],
                coverFoilingOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.coverFoilingOptions) || [],
                dustJacketFoilingOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.dustJacketFoilingOptions) || [],
            }, enableReinitialize: true, onSubmit: handleSave }, function (_a) {
            var isSubmitting = _a.isSubmitting, values = _a.values, setFieldValue = _a.setFieldValue, setSubmitting = _a.setSubmitting;
            return (React.createElement(React.Fragment, null,
                React.createElement(Card, { className: "p-4 shadow-sm bg-light" },
                    React.createElement(FormikForm, null,
                        React.createElement("h5", { className: "mb-4" }, "Foiling Metadata"),
                        successMessage && (React.createElement(Alert, { variant: "success", onClose: function () { return setSuccessMessage(null); }, dismissible: true }, successMessage)),
                        error && (React.createElement(Alert, { variant: "danger", onClose: function () { return setError(null); }, dismissible: true }, error)),
                        subProductId !== 0 && (React.createElement(Form.Group, { className: "mb-4" },
                            React.createElement(Form.Check, { type: "radio", label: "Use Default Metadata", checked: !useCustom, onChange: function () { return setUseCustom(false); } }),
                            React.createElement(Form.Check, { type: "radio", label: "Customize Metadata", checked: useCustom, onChange: function () { return setUseCustom(true); } }),
                            React.createElement("hr", null),
                            useCustom && (React.createElement(Button, { className: "mt-1 btn-sm", variant: "secondary", onClick: handleCopyFromDefault }, "Copy Default Values")))),
                        frontAvailable &&
                            renderTable('frontFoilingOptions', 'Front Options', values, setFieldValue),
                        backAvailable &&
                            renderTable('backFoilingOptions', 'Back Options', values, setFieldValue),
                        coverAvailable &&
                            renderTable('coverFoilingOptions', 'Cover Options', values, setFieldValue),
                        dustJacketAvailable &&
                            renderTable('dustJacketFoilingOptions', 'Dust Jacket Options', values, setFieldValue),
                        React.createElement(Button, { type: "submit", disabled: isSubmitting, variant: "primary", className: "mt-4" }, "Save Changes"))),
                React.createElement(ConfirmationModal, { show: showConfirmationModal, onHide: function () { return setShowConfirmationModal(false); }, onConfirm: function () {
                        setShowConfirmationModal(false);
                        saveFoilingMetadata(values, setSubmitting);
                    } }),
                React.createElement(Modal, { show: showModal, onHide: function () { return setShowModal(false); } },
                    React.createElement(Modal.Header, { closeButton: true },
                        React.createElement(Modal.Title, null, editIndex !== null ? 'Edit Option' : 'Add New Option')),
                    React.createElement(Modal.Body, null,
                        React.createElement(Form, null,
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Colours"),
                                React.createElement(Form.Control, { as: "select", value: newOption.foilingColour === null ? '' : newOption.foilingColour, onChange: function (e) {
                                        return setNewOption(function (prev) { return (__assign(__assign({}, prev), { foilingColour: e.target.value === '' ? null : e.target.value })); });
                                    } }, getFoilingColourOptions(sectionKey, values).map(function (option) {
                                    var _a, _b;
                                    return (React.createElement("option", { key: (_a = option.value) !== null && _a !== void 0 ? _a : 'null', value: (_b = option.value) !== null && _b !== void 0 ? _b : '', disabled: option.disabled }, option.label));
                                }))))),
                    React.createElement(Modal.Footer, null,
                        React.createElement(Button, { variant: "secondary", onClick: function () { return setShowModal(false); } }, "Close"),
                        React.createElement(Button, { variant: "primary", onClick: function () {
                                var updatedOptions = __spreadArray([], values[sectionKey], true);
                                if (editIndex !== null) {
                                    updatedOptions[editIndex] = newOption;
                                }
                                else {
                                    updatedOptions.push(newOption);
                                }
                                setFieldValue(sectionKey, updatedOptions);
                                setShowModal(false);
                            } }, editIndex !== null ? 'Save Changes' : 'Add Option')))));
        })));
};
export default FoilingMetaDataEditor;
