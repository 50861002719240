import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import axios from "axios";
import MomentZone from "moment-timezone";
import mixam from "../../../boot/mixam";

export default defineComponent(PublishingHouseDatatable);

function PublishingHouseDatatable() {

    this.attributes({
        url: "/admin/print-on-demand/publishing-house/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Name",
            type: "text",
            data: "name"
        });

        cols.push({
            title: "Markup type",
            type: "text",
            data: "markupType"
        });

        cols.push({
            title: "Markup value",
            type: "text",
            data: "markupValue"
        });

        cols.push({
            title: "",
            type: "multiButton",
            width: "180px",
            data: "actions"
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (publishingHouseData) {
        return publishingHouseData.map(publishingHouse => this.normalizePublishingHouseData(publishingHouse));
    };

    this.normalizePublishingHouseData = function (publishingHouse) {
        const result = $.extend(true, {}, publishingHouse);
        result.markupType = publishingHouse.markup.type;
        result.markupValue = publishingHouse.markup.value;
        result.actions = [
            {
                href: `/admin/print-on-demand/publishing-house/${publishingHouse.id}/edit`,
                caption: "Edit",
                className: "btn btn-primary",
                target: "_self"
            }
        ];
        return result;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
            }
        );
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
