// TODO: Separate this constant into each TableModal file
export var formInfo = {
    disputeCreditAction: {
        apiUrlTemplate: function (id) { return "/api/admin/dispute/".concat(id, "/update-credit-note"); },
        // data object:
        // object keys - what the submit api is expecting.
        // object values - passed into the component from shop; contain the initialValues.
        data: {
            problemActualCreditNoteAmountReceived: 'actualCreditNoteAmountReceived',
            problemCreditNoteRemarks: 'creditNoteRemarks',
            problemCreditNoteReceived: 'creditNoteReceived',
        },
    },
    taxExemptionAction: {
        apiUrlTemplate: function (id) { return "/admin/api/tax-exemption/".concat(id, "/update-certificate"); },
        data: {
            status: 'status',
            internalNotes: 'internalNotes',
            rejectionReason: 'rejectionReason',
            issueDate: { data: 'issueDate', type: 'date' },
            expiryDate: { data: 'expiryDate', type: 'date' },
            noExpiryDate: 'noExpiryDate'
        },
    },
    customerCredit: {
        apiUrlTemplate: function (id) { return "/admin/api/customer-credits/".concat(id, "/update"); },
        data: {
            type: 'type',
            status: 'status',
            amount: 'amount',
            expiryDate: { data: 'expiryDate', type: 'date' },
            internalNotes: 'internalNotes',
        },
    },
    DispatchDate: {
        apiUrlTemplate: function (id) { return "/admin/api/article/".concat(id, "/date"); },
        data: {
            date: { data: 'finalDate', type: 'date' },
        },
    },
    ArticleStatus: {
        apiUrlTemplate: function (id) { return "/admin/api/article/".concat(id, "/update"); },
        data: {
            status: 'status',
            tracking: 'tracking',
            estimatedDeliveryCost: 'estimatedDeliveryCost',
            silent: 'silent',
        },
    },
    ResellerCommand: {
        apiUrlTemplate: function (id) { return "/admin/api/resellers/".concat(id, "/create"); },
        data: {
            status: 'status',
            declineReason: 'declineReason'
        },
    },
    CreditControlStatus: {
        apiUrlTemplate: function (id) { return "/admin/api/credit/".concat(id, "/verify"); },
        data: {
            status: 'status',
            target: 'target'
        },
    },
    FulfilmentUpdate: {
        apiUrlTemplate: function (id) { return "/admin/api/fulfilment/".concat(id, "/update"); },
        data: {
            indispute: 'fulfillmentDispute',
            sum: 'fulfilmentSum',
            remark: 'fulfilmentRemark',
        },
    },
    TransferFunds: {
        apiUrlTemplate: function (id) { return "/admin/api/credit/".concat(id, "/verify"); },
        data: {
            status: 'status',
        },
    },
    DisputeAction: {
        apiUrlTemplate: function (id) { return "/admin/api/credit/".concat(id, "/verify"); },
        data: {},
    },
    SampleRequest: {
        apiUrlTemplate: function (id) { return "/admin/api/samplereq/".concat(id, "/status"); },
        data: {
            status: 'status',
        },
    },
    PublisherSettlement: {
        apiUrlTemplate: function (id) { return "/admin/print-on-demand/publisher/sales/".concat(id, "/settlement"); },
        data: {
            settled: 'settled',
            settlementAmount: 'settlementAmount',
            settlementDate: 'settlementDate',
            settledBy: 'settledBy',
            notes: 'notes'
        },
    },
    PublishingHouseSettlement: {
        apiUrlTemplate: function (id) { return "/admin/print-on-demand/publishing-house/sales/".concat(id, "/settlement"); },
        data: {
            settled: 'settled',
            settlementAmount: 'settlementAmount',
            settlementDate: 'settlementDate',
            settledBy: 'settledBy',
            notes: 'notes'
        },
    },
};
